import { combineReducers } from "redux";
import authReducer from './auth-slice';
import registrationReducer from "./registration-slice";
import twoFactorReducer from "./two-factor-slice";
import userReducer from "./user-slice";
import VerifySecurityReducer from "./personal-account/security-verify";
import passwordSecurityReducer from "./personal-account/security/password-security-slice";
import emailSecurityReducer from "./personal-account/security/email-security-slice";
import phoneSecurityReducer from "./personal-account/security/phone-security-slice";
import appSecurityReducer from "./personal-account/security/app-security-slice";
import profileReducer from "./personal-account/profile-slice";
import walletReducer from "./personal-account/wallet-slice";
import chartReducer from './chart-slice';
import statisticsReducer from './personal-account/statistics-slice';
import referralReducer from './personal-account/referral-slice';
import roomSitReducer from './sit-and-go/rooms-slice';
import menuReducer from "./menu-slice";
import settingsReducer from "./settings-slice";
import gameReducer from "./sit-and-go/game-slice";
import lobbyReducer from './lobby-slice';
import telegramReducer from './telegram-slice';
import notifiactionReducer from './notifiaction-slice';
import swiperFarmReducer from './swiper/swiper-farm-slice';
import swiperTasksReducer from './swiper/swiper-tasks-slice';
import swiperLeaderboardReducer from "./swiper/swiper-leaderboard-slice";
import appStatusReducer from "./app-status-slice";
import personalAccountReducer from "./new/personal-account-slice";
import pnlReducer from './pnl-slice';

export const rootReducers = combineReducers({
      auth: authReducer,
      registration: registrationReducer,
      twoFactor: twoFactorReducer,
      user: userReducer,
      wallet: walletReducer,
      VerifySecurity: VerifySecurityReducer,
      passwordSecurity: passwordSecurityReducer,
      emailSecurity: emailSecurityReducer,
      phoneSecurity: phoneSecurityReducer,
      appSecurity: appSecurityReducer,
      profile: profileReducer,
      referral: referralReducer,
      chart: chartReducer,
      statistics: statisticsReducer,
      menu: menuReducer,
      roomSit: roomSitReducer,
      game: gameReducer,
      settings: settingsReducer,
      lobby: lobbyReducer,
      telegram: telegramReducer,
      notifiaction: notifiactionReducer,
      swiperFarm: swiperFarmReducer,
      swiperTasks: swiperTasksReducer,
      swiperLeaderboard: swiperLeaderboardReducer,
      appStatus: appStatusReducer,
      personalAccount: personalAccountReducer,
      pnl: pnlReducer,
});

export type RootState = ReturnType<typeof rootReducers>;