import { FC, memo } from "react";
import { StyledSVG } from "./icon.styled";

export type IconProps = {
    size?: number;
    src: string;
    themeColor: string;
}

export const Icon : FC<IconProps> = memo(({ size = 24, src = '8C8C8F', themeColor }) => {
    return(
        <StyledSVG color={themeColor} src={src} size={size} />
    )
})