import { FC, memo, useCallback, useMemo } from "react"
import { StyledImageRoom, StyledRoomCardInfo } from "./room-card.styles";
import { Column } from "../column";
import { Row } from "../row";
import { Spacing } from "../spacing";
import { Text } from '../text';
import { getTimeFromRoomType } from "../../data/room-info";
import defualtIcon from '../../ui-kit/assets/player-room.svg';
import { Surface } from "../surface";
import { Pressable } from "../pressable";
import { themes } from "../themes/theme";


export type RoomCardProps = {
    id: number;
    type: string;
    playersWait: number;
    playersAll: number;
    amount: string;
    time: string;
    pictures: string[];
    currency: string;
    onClick: (roomId: number, currency: string) => void;
}

export const RoomCard: FC<RoomCardProps> = memo(({ ...rest }) => {
    const players = `${rest.playersWait}/${rest.playersAll}`
    const amount = `${rest.amount}${rest.type === "BTRT" ? `` : " ₮"}`
    const time = getTimeFromRoomType.get(rest.time);
    const click = useCallback(() => {
        rest.onClick(rest.id, rest.currency)
    }, [rest])
    const info = useMemo(() => {
        return [
            {
                name: "Players",
                data: players,
                border: "15px 5px 5px 15px",
            },
            {
                name: "Time",
                data: time || "15 MIN",
                border: "5px 5px 5px 5px",
            },
            {
                name: "Buy in",
                data: amount,
                border: "5px 15px 15px 5px",
            }
        ]
    }, [amount, players, time])

    const pictures = rest.pictures.length > 0 ? rest.pictures.slice(0, 3) : [defualtIcon, defualtIcon];

    return (
        <Surface themeColor="#1C1A22" radius={10} padding={[10, 0]} style={{ maxWidth: "290px" }}>
            <Column horizontalAlign="center" padding={[0, 25, 0, 25]}>
                <Row style={{ width: "100%" }} horizontalAlign="center" verticalAlign="center">
                    {info.map((item, index) =>
                        <>
                            <StyledRoomCardInfo borderRadius={item.border}>
                                <Column style={{ height: "100%" }} verticalAlign="center" horizontalAlign="center">
                                    <Text themeColor={themes.colors.gray} themeFont={themes.fonts.t}>
                                        {item.name}
                                    </Text>
                                    <Spacing themeSpace={5} variant="Column" />
                                    <Text
                                        themeColor={index + 1 === info.length ? themes.colors.primary : themes.colors.surface}
                                        themeFont={themes.fonts.h3}
                                        align="center"
                                    >
                                        {item.data}
                                    </Text>
                                </Column>
                            </StyledRoomCardInfo>
                            {index < info.length - 1 && <Spacing themeSpace={5} variant="Row" />}
                        </>
                    )}
                </Row>
                <Spacing themeSpace={10} variant="Column" />
                <Row horizontalAlign="center" style={{ width: "100%", maxWidth: "220px" }}>
                    <Row style={{ width: "100%", boxSizing: "border-box" }} verticalAlign="center" horizontalAlign="space-between">
                        <Row>
                            {pictures.map((item, index) =>
                                <StyledImageRoom style={{ marginLeft: `${index === 0 ? "0px" : "-25px"}` }} src={item} zIndex={index} />
                            )}
                        </Row>
                        <Pressable onClick={click}>
                            <Surface
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                radius={50}
                                height={40}
                                width={110}
                                themeColor={themes.colors.primary}
                            >
                                <Text themeColor="#1C1A22" themeFont={themes.fonts.h3}>Play</Text>
                            </Surface>
                        </Pressable>
                    </Row>
                </Row>
            </Column>
        </Surface>
    )
});