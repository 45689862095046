import { FC, memo } from "react"
import { Outlet } from "react-router";
import BitrateLogo from "../../images/bitrate-logo.svg";
import { useAuthentication } from "../../hooks/authentication-hook";
import { LoadingScreen, StyledLogoLoading } from "../../ui-kit/loading-view/loading-view";

export type PrivateRouteProps = {

}

export const PrivateRoute: FC<PrivateRouteProps> = memo(() => {
    const { loading } = useAuthentication();
    return (
        loading === "success" ?
            <Outlet /> :
            <LoadingScreen>
                <StyledLogoLoading src={BitrateLogo} />
            </LoadingScreen>
    )
})