import { FC, HTMLAttributes, ReactNode, memo } from "react";
import { BackgroundCloseButton, CustomCloseButtom, WrapperPopap } from "./popup.styled";
import { Image } from "../image";

import closeIcon from "../assets/close.svg";
import { Surface } from "../surface";

export type PopupProps = {
    borderRadius?: number;
    width?: string | number;
    themeColor?: string;
    themeColorBorder?: string;
    height?: number;
    padding?: number | [number?, number?, number?, number?];
    children? : ReactNode;
    closeByExternalClick?: boolean;
    showCloseButton?: boolean;
    isActive: boolean;
    closePopup: () => void;
} & HTMLAttributes<HTMLDivElement>

export const Popup : FC<PopupProps> = memo(({
    borderRadius=30, 
    width='auto', height, 
    padding=20, 
    children, 
    themeColor,
    themeColorBorder,
    closeByExternalClick=true,
    showCloseButton = true,
    isActive,
    closePopup,
     ...rest }) => {

    return(
        <WrapperPopap isActive={isActive} onClick={closeByExternalClick ? closePopup : () => {}}>
            <Surface themeColor={themeColor} themeColorBorder={themeColorBorder} radius={borderRadius} width={width} height={height} padding={padding} {...rest}>
            {children}
              {
                    showCloseButton && 
                    <CustomCloseButtom onClick={closePopup}>
                        <BackgroundCloseButton>
                            <Image src={closeIcon} size={15}/>
                        </BackgroundCloseButton>
                    </CustomCloseButtom>
              }
            </Surface>
        </WrapperPopap>
    )
})


