import { CSSProperties, FC, memo } from "react"
import { Column } from "../../../ui-kit/column";
import { Blur, GridPlayers, GridTopCard, RateBoardStyled, ScrollContainer, TopBoard } from "./end.styled";
import { Spacing } from "../../../ui-kit/spacing";
import { themes } from "../../../themes/theme";
import { Text } from "../../../ui-kit/text";
import { Image } from "../../../ui-kit/image";
import { Surface } from "../../../ui-kit/surface";
import { Row } from "../../../ui-kit/row";
import { Button } from "../../../ui-kit/button";
import { PlayerFinish } from "../../../api/sit-go/game-api";

export type EndViewProps = {
  topThree: PlayerFinish[];
  leaders: PlayerFinish[];
  time: string;
  rate: number;
  back: () => void;
}

export const EndView: FC<EndViewProps> = memo(({ topThree, leaders, time, rate, back }) => {
    

    const filterTop = topThree.map((item, index) => {
        return { ...item, place: index + 1 }
    }).sort((a, b) => {
        const order: { [key: number]: number } = { 2: -1, 1: 0, 3: 1 };
        return order[a.place] - order[b.place];
    });
    return (
        <Column style={{ height: "100vh", position: "relative" }} horizontalAlign="center">
            <Blur />
            <Spacing themeSpace={14} variant="Column" />
            <Text themeFont={themes.fonts.h1}>BITCOIN RATE</Text>
            <Spacing themeSpace={5} variant="Column" />
            <Text themeColor={themes.colors.primary} themeFont={themes.fonts.ht1}>at {time}</Text>
            <Spacing themeSpace={15} variant="Column" />
            <RateBoardStyled>
                <Text themeFont={themes.fonts.h4}>${rate}</Text>
            </RateBoardStyled>
            <Spacing themeSpace={10} variant="Column" />
            <ScrollContainer>
                <TopBoard>
                    <GridTopCard isOne={filterTop.length > 1 ? false : true}>
                        {filterTop.map((item) =>
                            <TopPlayer
                                max={item.place === 1 ? true : false}
                                img={item.img}
                                name={item.name}
                                bet={item.bet}
                                currency={item.currency}
                                place={item.place}
                                prize={item.prize}
                            />
                        )}
                    </GridTopCard>
                </TopBoard>
                <Spacing themeSpace={15} variant="Column" />
                <GridPlayers>
                    {leaders.map(item =>
                        <>
                            <Player
                                place={item.place}
                                name={item.name}
                                bet={item.bet}
                                currency={item.currency}
                                img={item.img}
                                prize={item.prize}
                            />
                            <Spacing themeSpace={5} variant="Column" />
                        </>
                    )}
                </GridPlayers>
            </ScrollContainer>
            <Spacing variant="Column" themeSpace={20} />
            <Text align="center" themeFont={themes.fonts.ht1}>
                Game details are stored in<br></br>your personal account
            </Text>
            <Spacing variant="Column" themeSpace={12} />
            <Button width={285} height={40} onClick={back}>
                <Text themeColor={themes.colors.background} align="center" themeFont={themes.fonts.ht1}>
                    Back to Lobby
                </Text>
            </Button>
            <Spacing variant="Column" themeSpace={25} />
        </Column>
    );
});

type TopPlayerProps = {
    max: boolean
    img: string;
    name: string
    bet: number;
    prize: number;
    currency: string;
    place: number;
}
const TopPlayer: FC<TopPlayerProps> = memo(({ max, img, name, bet, currency, place, prize }) => {
    const textNameStyle: CSSProperties = {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        textAlign: "center"
    }
    return (
        <Column style={{ width: "auto" }}>
            <Surface
                radius={60}
                width={max ? 100 : 70}
                height={max ? 100 : 70}
                themeColorBorder={max ? "linear-gradient(90deg, rgba(13,198,160,1) 0%, rgba(210,56,176,1) 100%) border-box;" : undefined}
            >
                <Column style={{ height: "100%", position: "relative" }} horizontalAlign="center" verticalAlign="center">
                    <Image src={img} size={max ? 85 : 55} />
                    <Surface
                        style={{ position: "absolute", bottom: "-10px" }}
                        radius={50}
                        width={21}
                        height={21}
                        themeColorBorder={max ? "linear-gradient(90deg, rgba(13,198,160,1) 0%, rgba(210,56,176,1) 100%) border-box;" : undefined}
                    >
                        <Column style={{ height: "100%" }} horizontalAlign="center" verticalAlign="center">
                            <Text themeFont={themes.fonts.h5} themeColor={themes.colors.secondary}>
                                {place}
                            </Text>
                        </Column>
                    </Surface>
                </Column>
                <Spacing themeSpace={15} variant="Column" />
                <Text style={textNameStyle} align="center" themeFont={themes.fonts.h5}>
                    {name}
                </Text>
                <Spacing themeSpace={4} variant="Column" />
                <Text style={{ fontSize: "8px" }} align="center" themeColor={themes.colors.primary} themeFont={themes.fonts.h5}>
                    {prize} {currency}
                </Text>
                <Spacing themeSpace={4} variant="Column" />
                <Text align="center" themeColor={themes.colors.secondary} themeFont={themes.fonts.ht2}>
                    ${bet}
                </Text>
            </Surface>
        </Column>
    )
});

type PlayerProps = {
    place: number;
    img: string;
    name: string;
    bet: number;
    prize: number;
    currency: string;
};

const Player: FC<PlayerProps> = memo(({ place, img, name, bet, currency, prize }) => {
    return (
        <Surface
            themeColorBorder={place === 1 ? "linear-gradient(90deg, rgba(13,198,160,1) 0%, rgba(210,56,176,1) 100%) border-box;" : undefined}
            style={{ padding: "10px" }}
        >
            <Row horizontalAlign="space-between" verticalAlign="center" style={{ width: "100%" }}>
                <Row verticalAlign="center">
                    <Text themeFont={themes.fonts.h4}>{place}.</Text>
                    <Spacing themeSpace={10} variant="Row" />
                    <Image style={{ borderRadius: "50%" }} src={img} size={35} />
                    <Spacing themeSpace={10} variant="Row" />
                    <Text style={{ overflow: "hidden", overflowWrap: "anywhere" }} themeFont={themes.fonts.h4}>{name}</Text>
                </Row>
                <Row>
                    <Column horizontalAlign="flex-end">
                        <Text style={{ textAlign: "right" }} themeColor={themes.colors.foreground} themeFont={themes.fonts.ht2}>${bet}</Text>
                        {prize > 0 && <Text style={{ textAlign: "right" }} themeColor={themes.colors.primary} themeFont={themes.fonts.ht2}>{prize} {currency}</Text>}
                    </Column>
                </Row>
            </Row>
        </Surface>
    )
})

