import { FC, HTMLAttributes } from "react";
import { StyledImage } from "./image.styles";
import React from "react";


export type ImageProps = {
    src: string;
    size?: Number;
    width?: Number;
    height?: Number;
    alt?: string;
    className?: string;
    themeColor?: string;
} & HTMLAttributes<HTMLDivElement>;

export const Image: FC<ImageProps> = ({size, src, alt, className, width, height, themeColor, ...rest}) => (
    <StyledImage size={size} themeColor={themeColor} width={width ? width : 20} height={height ? height : 20} src={src} alt={alt} className={className} {...rest}  />
);
