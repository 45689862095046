import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

export const userApi = {
    getUserInfo(authToken: string) {
        return instance.get('/user/user-info', { 
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
    getLogos(authToken: string) {
        return instance.get('/user/logos', { 
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
    logout(authToken: string) {
        return instance.get('/user/user-info', { 
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
    updatePlayerName(playerName: string, authToken: string) {
        return instance.post('/user/update',{
            playerName: playerName 
            }, { headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
    changePicture(picture: string, authToken: string) {
        return instance.post('/user/update',{
            picture: picture 
            }, { headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
    changeUserTimeZone(userTimeZone: string, authToken: string) {
        return instance.post('/user/update',{
            userTimeZone: userTimeZone 
            }, { headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
    changeUserLanguage(language: string, authToken: string) {
        return instance.post('/user/update',{
            language: language 
            }, { headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    }
}