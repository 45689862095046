import { FC, memo } from "react";
import { FooterBackground, SelectButton } from "./footer.styled";
import { Text } from '../text';
import farmIcon from '../assets/farm.svg';
import taskIcon from '../assets/tasks.svg';
import topIcon from '../assets/top.svg';
import gamesIcon from '../assets/games.svg';
import { themes } from "../themes/theme";
import { Icon } from "../icon";
import { Pressable } from "../pressable";
import { Spacing } from "../../сomponents/spacing";

export type FooterProps = {
  type: "Farm" | "Task" | "Top" | "Games";
  onLeaderboard: () => void;
  onTask: () => void;
  onFarm: () => void;
  onGames: () => void;
}

export const Footer: FC<FooterProps> = memo(({ type, onLeaderboard, onFarm, onTask, onGames }) => {
  
  const items = [
    {
      title: "Farm",
      icon: farmIcon,
      hidden: false,
      local: onFarm
    },
    {
      title: "Task",
      icon: taskIcon,
      hidden: false,
      local: onTask
    },
    {
      title: "Top",
      icon: topIcon,
      hidden: false,
      local: onLeaderboard,
    },
    {
      title: "Games",
      icon: gamesIcon,
      hidden: false,
      local: onGames,
    }
  ];


  const filtered = items.map((item) => item.title === type ? { ...item, hidden: true } : item);
  return (
    <FooterBackground>
      {filtered.map((item) =>
        <Pressable onClick={item.local}>
          <SelectButton active={item.hidden}>
            <Icon themeColor={item.hidden ? themes.colors.background : item.title === "Games" ? themes.colors.primary : themes.colors.gray} src={item.icon} size={16} />
            <Spacing variant="Column" themeSpace={2} />
            <Text themeColor={item.hidden ? themes.colors.background : item.title === "Games" ? themes.colors.primary : themes.colors.gray} themeFont={themes.fonts.ht2}>{item.title}</Text>
          </SelectButton>
        </Pressable>
      )}
    </FooterBackground>
  );
})